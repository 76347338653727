// src/components/AboutUs.js

import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
    padding: 100px 0;
    background-color: #1a1a1a;
    color: #315c81;
    text-align: center;
`;

const AboutTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const AboutDescription = styled.p`
    max-width: 800px;
    margin: 0 auto 20px;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #419a32;
`;

const AboutUs = () => {
  return (
    <AboutSection id="about">
      <AboutTitle>About Us</AboutTitle>
      <AboutDescription>
        At SkyLabs Developments, we believe that every challenge deserves a solution tailored to its unique needs. We reject the notion that customers should adapt to off-the-shelf, generic solutions.
        Every process, every problem, every task is distinct—and so too should be its solution. One size does not fit all.
      </AboutDescription>
      <AboutDescription>
        Our mission is to bring bold ideas to life through innovative technology and forward-thinking design. We don't just develop products; we craft cutting-edge solutions that blend creativity with functionality. Rather than relying solely on existing frameworks and predefined tools, we approach every project with fresh imagination, crafting new technologies or adapting existing ones to meet the specific demands of the task at hand.
      </AboutDescription>
      <AboutDescription>
        We combine the best of current technologies with our own innovations to transform ideas into reality. It's a process of constant evolution, where ingenuity and adaptability fuel our journey towards groundbreaking results.
      </AboutDescription>
      <AboutDescription><strong>Industry:</strong> Business Intelligence Platforms</AboutDescription>
    </AboutSection>
  );
};

export default AboutUs;