// src/components/ContactUs.js

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios'; // Import Axios to make HTTP requests

const ContactSection = styled.section`
  padding: 80px 0;
  background-color: #1a1a1a;
  color: #226772;
  text-align: center;
`;

const ContactTitle = styled.h2`
  font-size: 2.0rem;
  margin-bottom: 30px;
`;

const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 3px;
  border: 1px solid rgba(53, 119, 157, 0.61);
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #044356;
  font-size: 1rem;
  resize: vertical;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: rgba(9, 75, 84, 0.7);
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(9, 75, 84, 1);
  }
`;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email format
    if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post('port={8080}/send-email', formData);

      if (response.status === 200) {
        setStatus('Message sent successfully!');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('Error sending message. Please try again later.');
    }
  };

  return (
    <ContactSection id="contact">
      <ContactTitle>Contact Us</ContactTitle>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextArea
          name="message"
          rows="5"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Send</SubmitButton>
      </Form>
      {status && <p>{status}</p>}
    </ContactSection>
  );
};

export default ContactUs;
